<template>
  <v-container>
    <v-row>
      <v-col col="12" sm="12" md="12">
        <h2>Educational Background</h2>
        <v-divider></v-divider>
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-btn color="primary" class="mr-4 text--white" @click="openDialog()"
          >Add Info...</v-btn
        >
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-data-table :headers="headers" :items="desserts" class="elevation-1">
          <template v-slot:item="row">
            <tr>
              <td nowrap>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="updates(row.item)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
              </td>
              <td>{{ row.item.Level }}</td>
              <td>{{ row.item.School }}</td>
              <td>{{ row.item.Degree }}</td>
              <td>{{ row.item.From }}</td>
              <td>{{ row.item.To }}</td>
              <td>{{ row.item.HLUE }}</td>
              <td>{{ row.item.YearGrad }}</td>
              <td>{{ row.item.Scholarship }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent scrollable max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ Level }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="Level"
                      :items="levelOpt"
                      :rules="[vv.init(this.Level), vv.requiredSelect()]"
                      item-text="name"
                      label="Level"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="School"
                      label="School"
                      :rules="[
                        vv.init(this.School),
                        vv.required(),
                        vv.maxlen(255),
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="Degree"
                      label="Degree"
                      :rules="[
                        vv.init(this.Degree),
                        vv.required(),
                        vv.maxlen(255),
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="From"
                      label="From"
                      
                      :rules="[
                        vv.init(this.From),
                        vv.required()
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="To"
                      label="To"
                     
                      :rules="[
                        vv.init(this.To),
                        vv.required()
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="HLUE"
                      label="Highest Level/Units earned"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
              
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="YearGrad"
                      :items="yearItem"
                      :rules="[vv.init(this.YearGrad), vv.requiredSelect()]"
                      label="Year Graduated"
                      outlined
                      dense
                    ></v-autocomplete>
                   
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="mb-0">
                    <v-text-field
                      v-model="Scholarship"
                      label="Scholarship"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                   
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Close</v-btn
            >
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="savechanges()"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import vldn from "@/js/validation";
export default {
  name: "Educ",
  data: () => ({
    valid: false,
    formTitle: "",
    dialog: false,
    itemYears: [],
    headers: [
      {
        text: "ACTION",
        align: "start",
        sortable: false,
        value: "action",
      },
      {
        text: "LEVEL",
        sortable: false,
        value: "Level",
      },
      { text: "NAME OF SCHOOL", sortable: false, value: "School" },
      {
        text: "BASIC EDUCATION/DEGREE/COURSE",
        sortable: false,
        value: "Degree",
      },
      { text: "From", sortable: false, value: "From" },
      { text: "To", sortable: false, value: "To" },
      { text: "HIGHEST LEVEL/UNITS EARNED", sortable: false, value: "HLUE" },
      { text: "YEAR GRADUATED", sortable: false, value: "YearGrad" },
      {
        text: "SCHOLARSHIP/ ACADEMIC HONORS RECEIVED",
        sortable: false,
        value: "Scholarship",
      },
    ],
    desserts: [],
    levelOpt: [
      { name: "ELEMENTARY", value: "ELEMENTARY" },
      { name: "SECONDARY", value: "SECONDARY" },
      { name: "VOCATIONAL", value: "VOCATIONAL" },
      { name: "COLLEGE", value: "COLLEGE" },
      { name: "GRADUATE STUDIES", value: "GRADUATE STUDIES" }
    ],
    ID: 0,
    Level: "",
    School: "",
    Degree: "",
    From: "",
    To: "",
    HLUE: "",
    YearGrad: "",
    Scholarship: "",
    vv: vldn.methods,
  }),
  componets: {
    vldn,
  },
  computed: {
    yearItem(){
      return this.itemYears;
    }
  },
  created(){
    this.fetchYears();
  },
  mounted() {
    this.$api.methods.init();
    this.geteduc();
    
  },
  methods: {
    fetchYears(){
      const d = new Date();
      let year = d.getFullYear();
      this.itemYears = [];
      for(var i = year; i >= 1900; i--){
        this.itemYears.push(i.toString());
      }
    },
    openDialog() {
      this.ID = 0;
      this.Level = "ELEMENTARY";
      this.School = "";
      this.Degree = "";
      this.From = "";
      this.To = "";
      this.HLUE = "";
      this.YearGrad = "";
      this.Scholarship = "";
      this.dialog = true;
    },
    geteduc() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "pds/educ",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            this.desserts = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    updates(item) {
      this.ID = item.ID;
      this.Level = item.Level;
      this.School = item.School;
      this.Degree = item.Degree;
      this.From = item.From;
      this.To = item.To;
      this.HLUE = item.HLUE;
      this.YearGrad = item.YearGrad;
      this.Scholarship = item.Scholarship;
      this.dialog = true;
    },
    savechanges() {
      console.log(this.Level);
      if (this.$refs.form.validate()) {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_BASE_URL + "pds/updateeduc",
          headers: {
            Authorization: "Bearer" + localStorage.getItem("usertoken"),
          },
          data: {
            ID: this.ID,
            Level: this.Level,
            School: this.School,
            Degree: this.Degree,
            From: this.From,
            To: this.To,
            HLUE: this.HLUE,
            YearGrad: this.YearGrad,
            Scholarship: this.Scholarship,
          },
        })
          .then((response) => {
            if (response) {
              this.geteduc();
              this.ID = 0;
              this.Level = "";
              this.School = "";
              this.Degree = "";
              this.From = "";
              this.To = "";
              this.HLUE = "";
              this.YearGrad = "";
              this.Scholarship = "";
              this.dialog = false;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 500) {
                //
              }
            }
          });
      }
    },
  },
};
</script>
<style>
.container {
  max-width: 80%;
}
</style>
